<template>
    <Form
        class="user-form form-narrow"
        ref="form"
        :submit="handleSubmit"
        :initialValues="initialValues"
        @change.self="handleFormChange"
    >
        <Wizard ref="steps" @cancel="$emit('close')">
            <WizardPage title="1. personal info" class="flex h-full">
                <div class="side-panel">
                    <DetailsSidePanel>
                        <template v-slot:heading>basic user details</template>
                        <template v-slot:text>
                            Enter the basic information about the user, including required fields.
                        </template>
                    </DetailsSidePanel>
                </div>
                <div class="w-2/3">
                    <div class="form-section-title">authentication information</div>
                    <div v-if="isNewUser" class="form-row">
                        <TextField
                            name="email"
                            label="email*"
                            :validate="required"
                            hint="this email is used to sign in and can not be changed later"
                        />
                        <PhoneInput
                            name="phoneNumber"
                            label="phone number"
                            :validate="usPhoneNumber"
                            helperText="this number is used to sign in and can not be changed later"
                        />
                    </div>
                    <div v-else class="form-row identity-list">
                        <List
                            :columns="identityColumns"
                            :items="identities"
                            :css="{ bodyRowClass: 'row' }"
                        ></List>
                    </div>
                    <div class="form-section-title">contact information</div>
                    <div class="form-row">
                        <TextField name="firstName" label="first name*" :validate="required"/>
                        <TextField name="lastName" label="last name*" :validate="required"/>
                    </div>
                    <div class="form-row">
                        <PhoneInput
                            name="workPhoneNumber"
                            label="work phone"
                            :validate="usPhoneNumber"
                        />
                        <PhoneInput name="personalPhoneNumber" label="personal phone" :validate="usPhoneNumber"/>
                    </div>
                    <div class="form-row w-1/2 pr-4">
                        <PhoneInput name="smsPhoneNumber" label="sms number" :validate="usPhoneNumber"/>
                    </div>
                    <div class="form-row mt-6">
                        <Tooltip :text="mfaTooltipText">
                            <template v-slot:label>
                                <CheckboxInput
                                    name="mfaRequired"
                                    label="force to set up MFA (multi factor authentication)"
                                    :disabled="isAdmin && formValues.mfaRequired"
                                />
                            </template>
                        </Tooltip>
                    </div>
                </div>
            </WizardPage>

            <WizardPage title="2. communities" class="flex h-full">
                <div class="side-panel">
                    <DetailsSidePanel>
                        <template v-slot:heading>available communities</template>
                        <template v-slot:text>Select all communities this user should have access to</template>
                    </DetailsSidePanel>
                </div>
                <div class="w-2/3">
                    <div class="form-col">
                        <div class="form-row">
                            <DropdownMultiselectInput
                                name="communities"
                                label="communities"
                                :options="communitiesOptions"
                                :customLabel="(option) => (`${option.value}${option.nonlicensed? ' (no license)' : ''}`)"
                                :hide-selected="false"
                                :hide-tags="true"
                                :all-option="true"
                            >
                                <template v-slot:tags="{ value, onRemove }">
                                    <div v-for="option in value" :key="option.key" class="inline-flex">
                                        <Tooltip :text="optionTooltip(option)">
                                            <template v-slot:label>
                                                <Tag
                                                    :text="option.value"
                                                    class="mb-1"
                                                    :class="{'invalid-community': option.nonlicensed || (submitted && nonAssignedCommunityIds.includes(option.key))}"
                                                    @click="onRemove(option.key)"
                                                    deletable
                                                />
                                            </template>
                                        </Tooltip>
                                    </div>
                                </template>
                            </DropdownMultiselectInput>
                        </div>
                    </div>
                </div>
            </WizardPage>

            <WizardPage title="3. permissions" class="flex h-full">
                <div class="side-panel side-panel-gray">
                    <DetailsSidePanel>
                        <template v-slot:heading>permissions</template>
                        <template v-slot:text>
                            <p class="text-sm font-inter text-blue-800 mt-4 font-500">
                                Select an application to assign roles
                            </p>
                            <div class="flex-column space-y-2 mt-4">
                                <div
                                    v-for="(app, index) in apps"
                                    class="app-tab"
                                    :class="{active: activeAppTab === app.appId }"
                                    :key="index"
                                    @click="activeAppTab = app.appId"
                                >
                                    {{ app.appName }}
                                </div>
                            </div>
                        </template>
                    </DetailsSidePanel>
                </div>
                <div class="w-2/3 flex-1 overflow-auto">
                    <div class="form-col pt-4">
                        <InfoBlock v-if="!rolesAvailable" class="flex">
                            <Icon name="warning" class="w-4 h-4 mr-2 flex-shrink-0"/>
                            <div>
                                <p class="mb-2">
                                    This application is not authorized for the selected communities.
                                </p>
                                <router-link
                                    :to="{name: 'customer.communities.index', params: {customerId: customerId}}"
                                    class="underline"
                                >
                                    Please check communities licensing options.
                                </router-link>
                            </div>
                        </InfoBlock>

                        <div v-if="communitiesVisible" class="mb-10">
                            <label>This application is licensed to operate in the following communities:</label>
                            <div>
                                <Tag v-for="(item, index) in activeAppCommunities"
                                     :key="index"
                                     :text="item.communityName"
                                ></Tag>
                            </div>
                        </div>

                        <div
                            v-for="(app) in apps"
                            :key="app.appId"
                            v-show="activeAppTab === app.appId && rolesAvailable"
                        >
                            <label>roles</label>
                            <p class="text-sm font-600">
                                Choose from the below roles for the user for the selected application
                            </p>
                            <RadioInput
                                :key="`${app.appId}.undefined`"
                                :name="`appRoles[${app.appId}]`"
                                :value="NO_ROLE_ALIAS"
                                label="no role"
                                hint="User is forbidden accessing this application"
                            />
                            <RadioInput
                                v-for="role in app.roles"
                                :key="role.appRoleId"
                                :name="`appRoles[${app.appId}]`"
                                :value="role.appRoleId"
                                :label="role.alias"
                                :hint="role.description"
                            />
                            <InfoBlock v-if="authAppActive && (isAdmin || formValues.mfaRequired)" class="flex mt-6">
                                <Icon name="warning" class="w-4 h-4 mr-2 flex-shrink-0"
                                      :style="{marginTop: '0.125rem'}"/>
                                <div>{{ infoMfaText }}</div>
                            </InfoBlock>
                        </div>
                    </div>
                </div>
            </WizardPage>
        </Wizard>
    </Form>
</template>

<script>
import { differenceBy, intersection, isEqual, sortBy, uniq, uniqBy } from 'lodash-es';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import TextField from '@/components/form/TextField';
import PhoneInput from "@/components/form/PhoneInput";
import CheckboxInput from '@/components/auth/form/CheckboxInput';
import Form from '@/components/form/Form';
import DropdownMultiselectInput from '@/components/auth/form/DropdownMultiselectInput';
import Wizard from '@/components/ui/wizard/Wizard';
import WizardPage from '@/components/ui/wizard/WizardPage';
import RadioInput from '@/components/auth/form/RadioInput';
import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
import InfoBlock from '@/components/auth/InfoBlock';
import Icon from '@/components/ui/Icon';
import List from "@/components/list/List";
import Tag from '@/components/ui/Tag';
import Tooltip from '@/components/ui/Tooltip';
import { AUTH_ALIAS, AUTH_NAME, BUNDLE_TYPES, ROLES } from '@/components/auth/constants';

export default {
    name: 'UserForm',
    components: {
        Icon,
        InfoBlock,
        DetailsSidePanel,
        RadioInput,
        WizardPage,
        Wizard,
        DropdownMultiselectInput,
        List,
        TextField,
        Form,
        PhoneInput,
        Tag,
        CheckboxInput,
        Tooltip,
    },
    mixins: [
        ConfirmationMixin,
        ValidatorMixin,
        NotifyMixin,
    ],
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
        initialValues: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        admin: {
            type: Boolean,
            required: false,
            default: false,
        },
        stepStartWith: {
            type: Number,
            default: 0,
        },
    },
    emits: ['close'],
    data() {
        return {
            activeAppTab: undefined,
            authRoles: [],
            customerCommunities: [],
            selectedCommunities: [],
            licensedBundles: [],
            formValues: {},
            infoMfaText: '',
            NO_ROLE_ALIAS: ROLES.NO_ROLE,
            submitted: false, // a flag to detect a first try to submit the form
            identityColumns: [
                {
                    name: 'email',
                    title: 'email',
                    class: 'w-1/3 identity-cell',
                },
                {
                    name: 'phone',
                    title: 'phone number',
                    class: 'w-1/3 identity-cell',
                },
                {
                    name: 'cognitoUserId',
                    title: 'cognito user id',
                    class: 'w-1/3 identity-cell',
                },
            ],
        };
    },
    computed: {
        activeAppCommunities() {
            return this.licensedCommunitiesPerApp[this.activeAppTab];
        },
        allCommunitiesSelected() {
            return this.selectedCommunities[0]?.key === 'all';
        },
        apps() {
            return [
                {
                    appId: AUTH_ALIAS,
                    appName: AUTH_NAME,
                    communityAgnostic: true,
                    roles: this.authRoles,
                },
                ...this.licensedBundles,
            ]
        },
        authAppActive() {
            return this.activeAppTab === AUTH_ALIAS;
        },
        customerId() {
            return this.$route.params.customerId;
        },
        communitiesOptions() {
            return this.customerCommunities.map(({communityId: key, communityName: value}) => ({
                key,
                value,
                nonlicensed: !this.licensedCommunities.find(i => i.communityId === key),
            }));
        },
        communitiesVisible() {
            return !this.authAppActive && this.activeAppCommunities?.length > 0;
        },
        identities() {
          return this.initialValues.identities || [];
        },
        isAdmin() {
            const currentAuthRole = this.formValues.appRoles?.[AUTH_ALIAS];
            return this.admin || [ROLES.CA, ROLES.CA_READONLY].includes(currentAuthRole);
        },
        isNewUser() {
            return !this.initialValues.profileId;
        },
        licensedCommunities() {
            return this.licensedBundles.reduce((acc, bundle) => {
                return uniqBy([...acc, ...bundle.communities], 'communityId');
            }, []);
        },
        licensedCommunitiesPerApp() {
            const communities = this.allCommunitiesSelected ? this.communitiesOptions : this.selectedCommunities;
            const selectedCommunitiesIds = communities.map(c => c.key);
            return this.licensedBundles.reduce((acc, bundle) => {
                return {
                    ...acc,
                    [bundle.appId]: sortBy(
                        bundle.communities.filter(({communityId}) => selectedCommunitiesIds.includes(communityId)),
                        'communityName',
                    ),
                }
            }, {});
        },
        mfaTooltipText() {
            return this.isAdmin
                ? 'multi factor authentication is mandatory for admins'
                : null;
        },
        nonAssignedCommunities() {
            if (this.allCommunitiesSelected) {
                return differenceBy(
                    this.customerCommunities,
                    this.licensedCommunities,
                    'communityId',
                );
            }

            const {appRoles = {}} = this.formValues;
            const communityIds = Object.entries(appRoles).reduce((acc, [appId, appRoleId]) => {
                if (appRoleId && appRoleId !== ROLES.NO_ROLE) {
                    const communitiesPerApp = this.licensedCommunitiesPerApp[appId]?.map(({communityId}) => (communityId)) || [];
                    return [...acc, ...communitiesPerApp];
                }
                return acc;
            }, []);

            return differenceBy(
                this.selectedCommunities.map(({key: communityId, value: communityName}) => ({
                    communityId,
                    communityName,
                })),
                uniq(communityIds).map((communityId) => ({communityId})),
                'communityId',
            ) || [];
        },
        nonAssignedCommunityIds() {
            return this.nonAssignedCommunities.map(({communityId}) => (communityId));
        },
        rolesAvailable() {
            return this.authAppActive || this.activeAppCommunities?.length > 0;
        },
    },
    methods: {
        handleFormChange(state) {
            this.formValues = state.values;
        },

        convertValues({appRoles = {}, communities = [], ...values}) {
            const isGlobalRole = communities[0]?.key === 'all';

            const communitiesIds = communities.map(({key}) => key);

            const roles = this.licensedBundles
                .reduce((acc, {appId, type, communities: licensedCommunities}) => {
                    const appRoleId = appRoles[appId];

                    if (appRoleId && appRoleId !== ROLES.NO_ROLE) {
                        const appCommunitiesIds = !isGlobalRole
                            && intersection(communitiesIds, licensedCommunities.map(({communityId}) => communityId)) || [];

                        if (type === BUNDLE_TYPES.STAND_ALONE) {
                            acc.appRoles.push({
                                appId,
                                roles: isGlobalRole
                                    ? [{appRoleId, global: true}]
                                    : appCommunitiesIds.map((communityId) => ({appRoleId, communityId, global: false})),
                            });

                        } else {
                            acc.metaRoles.push({
                                metaRoleId: appRoleId,
                                isGlobal: isGlobalRole,
                                communities: isGlobalRole ? null : appCommunitiesIds,
                            });
                        }
                    }

                    return acc;
                }, {
                    appRoles: [], // for STAND_ALONE bundles
                    metaRoles: [], // for SUITE bundles
                });

            return {
                ...values,
                ...roles,
                role: appRoles[AUTH_ALIAS] === ROLES.NO_ROLE ? ROLES.USER : appRoles[AUTH_ALIAS],
            };
        },

        optionTooltip(option) {
            if (option.nonlicensed) {
                return 'this community is not licensed for any of the apps';
            }
            if (this.submitted && this.nonAssignedCommunityIds.includes(option.key)) {
                return 'user has no role assigned in the apps licensed for this community, select few roles on the next step';
            }
            return null;
        },

        fetchCommunities() {
            this.$authDataProvider.getList('customerCommunities', {
                customerId: this.customerId,
                size: 999,
                sort: 'name',
                nonDeleted: true,
            })
                .then(({content}) => {
                    this.customerCommunities = content.map(({id: communityId, name: communityName, ...rest}) => ({
                        ...rest,
                        communityId,
                        communityName,
                    }));
                })
                .catch(error => this.notifyError(error.message));
        },

        fetchAuthRoles() {
            this.$authDataProvider.getList('customerAuthRoles')
                .then(data =>
                    this.authRoles = data.filter(role => role.value !== ROLES.USER)
                        .map(({name: alias, value: appRoleId}) => ({alias, appRoleId})),
                )
                .catch(error => this.notifyError(error.message));
        },

        fetchCustomerBundles() {
            Promise.all([
                this.$authDataProvider.getList('bundles', {
                    page: 0,
                    size: 999,
                    nonDeleted: true,
                }),
                this.$authDataProvider.getOne('customers', {id: this.customerId}),
            ]).then(([bundles, customer]) => {
                const bundleMap = bundles.content.reduce((acc, item) => ({
                    ...acc,
                    [item.id]: item,
                }), {});

                this.licensedBundles = customer.licenses
                    .map(({bundleId, bundleName, communities = []}) => {
                        const {metaroles = [], type, appId, apps = []} = bundleMap[bundleId] || {};
                        const roles = type === BUNDLE_TYPES.STAND_ALONE
                            ? apps[0].roles.map(({id: appRoleId, alias, description}) => ({
                                alias,
                                appRoleId,
                                description,
                            }))
                            : metaroles.map(({name: alias, id: appRoleId, description}) => ({
                                alias,
                                appRoleId,
                                description,
                            }));

                        return {
                            appId: appId || bundleId, // For STAND_ALONE bundle - 'appId: <appId>', and for SUITE bundle - 'appId: null'
                            appName: bundleName,
                            bundleId,
                            communities,
                            isCustomerUserAgnostic: type === BUNDLE_TYPES.STAND_ALONE && apps[0].isCustomerUserAgnostic,
                            roles,
                            type,
                        }
                    })
                    .filter(({isCustomerUserAgnostic, roles}) => !isCustomerUserAgnostic && roles?.length > 0);
            }).catch(error => this.notifyError(error.message));
        },

        handleSubmit(values) {
            const currentStep = this.$refs.steps.currentStep;

            if (currentStep !== 2) {
                this.$refs.steps.goNext();
                return;
            }

            const payload = this.convertValues(values);

            if (this.nonAssignedCommunities.length > 0) {
                const communityTemplate = this.nonAssignedCommunities.length === 1
                    ? `community ${this.nonAssignedCommunities[0].communityName}`
                    : this.nonAssignedCommunities.length === 2
                        ? `communities ${this.nonAssignedCommunities[0].communityName} and ${this.nonAssignedCommunities[1].communityName}`
                        : `communities ${this.nonAssignedCommunities[0].communityName} and ${this.nonAssignedCommunities.length - 1} more`;

                this.requestConfirmation({
                    confirmationMessage: `User has no roles assigned in ${communityTemplate}. Are you sure you want to submit the provided configuration?`,
                    confirmBtnText: 'yes',
                    cancelBtnText: 'no',
                    confirmationType: 'warning',
                })
                    .then(confirmed => {
                        this.submitted = true;

                        if (!confirmed) {
                            return null;
                        }

                        this.onSubmit(payload);
                    });
            } else {
                this.onSubmit(payload);
            }
        },
    },

    created() {
        this.formValues = this.initialValues;

        Promise.all([
            this.fetchCommunities(),
            this.fetchAuthRoles(),
            this.fetchCustomerBundles(),
        ]);
    },

    mounted() {
        this.$refs.steps.setStep(this.stepStartWith);

        this.$watch(
            () => this.formValues.communities,
            (communities) => {
                this.selectedCommunities = communities;
            });

        this.$watch(
            () => this.apps,
            (val, oldVal) => {
                if (!isEqual(val, oldVal)) {
                    this.activeAppTab = val[0].appId;
                }
            });

        this.$watch(
            () => this.isAdmin,
            (value, prevValue) => {
                // automatically enable MFA for auth admins
                if (value) {
                    this.$refs.form?.formApi.change('mfaRequired', true);
                    this.infoMfaText = 'This user will be forced to set up MFA if it is not done yet.'
                } else if (prevValue) {
                    this.infoMfaText = 'MFA setup has been automatically enabled for this user. If you do not need it anymore please go back to step 1.personal info and check the settings.'
                }
            }, {
                immediate: true,
            });

        this.$watch(
            () => this.licensedBundles,
            (value) => {
                const missedRoles = {};
                value?.forEach(({appId}) => {
                    if (!this.formValues.appRoles?.[appId]) {
                        missedRoles[appId] = this.NO_ROLE_ALIAS;
                    }
                });
                this.$refs.form?.formApi.change('appRoles', {...this.formValues.appRoles, ...missedRoles});
            }, {
                immediate: true,
            },
        );
    },
};
</script>

<style scoped>
.app-container {
    @apply flex justify-between items-center border-t py-2;
}

.app-tab {
    @apply flex flex-grow border border-gray-300 p-2 cursor-pointer text-black;
}

.app-tab.active {
    @apply border-blue-500 text-blue-500;
}

.app-tab.error {
    @apply border-red-500 text-red-500;
}

.multiselect__tags-wrap, .multiselect__tag {
    display: none !important;
}

.side-panel-gray {
    @apply bg-gray-100;
}

.user-form {
    @apply flex;
}

.identity-list :deep(.row > .identity-cell) {
    @apply text-xs break-all;
}

.user-form,
.user-form form > div {
    @apply h-full;
}

.tag.invalid-community {
    @apply bg-red-100 text-red-400;
}
</style>
